import React from "react";
import { Container, Row, Col } from "reactstrap";

export default function ListPlayers() {
  return (
    <div className="view">
      <Container>
        <Row>
          <Col>ListPlayers</Col>
        </Row>
      </Container>
    </div>
  );
}
