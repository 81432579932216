import React from 'react';
import { Container, Row, Col } from 'reactstrap';

export default function Login() {
  return (
    <div className="view">
      <Container>
        <Row>
          <Col>
            Login
          </Col>
        </Row>
      </Container>
    </div>
  )
}